<template>
  <v-container class="login fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10" xl="8">
        <v-card color="background" elevation="0">
          <v-row no-gutters class="flex-md-row-reverse">
            <v-col cols="12" md="6" lg="7">
              <div class="pa-10 pa-md-16 welcome-splash">
                <v-row no-gutters class="fill-height">
                  <v-col class="d-flex flex-column justify-space-between">
                    <v-img
                      alt="Logo"
                      class="shrink mr-2"
                      contain
                      :src="require('../assets/logo.png')"
                      max-height="30"
                      max-width="100"
                    />
                    <h1 class="supersize py-4">
                      Vocus AWS Connect
                      <br />Wallboard
                    </h1>
                    <p class="mb-0">
                      Welcome to your AWS Connect Wallboard.
                      <br />Login to continue.
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="5" class="pa-10 pa-md-16">
              <h2 class="text-center">Log in</h2>
              <v-card-text class="text-center pt-0 pb-4 pb-md-12">
                With your Vocus credentials.
              </v-card-text>
              <v-card-text class="pa-0">
                <div v-if="loading">
                  <div class="text-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <h3>Loading profile...</h3>
                  </div>
                </div>
                <div v-else id="okta-signin-container"></div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { auth } from "@/firebase";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

export default {
  name: "Login",
  data() {
    return {
      loading: false
    };
  },
  metaInfo() {
    return {
      title: "Login"
    };
  },
  mounted() {
    this.$nextTick(async () => {
      const oktaUrl = "https://vocus.okta.com";
      this.widget = new OktaSignIn({
        baseUrl: oktaUrl,
        clientId: "0oaaoge5psbak9Stq2p7",
        redirectUri: "https://vocuswb.smartspeak.ai/login",
        authParams: {
          pkce: true,
          issuer: `${oktaUrl}/oauth2/ausaoz30odI2eqrGy2p7`,
          display: "page",
          scopes: ["openid", "profile", "email"]
        }
      });
      this.widget.renderEl(
        { el: "#okta-signin-container" },
        () => {
          /**
           * In this flow, the success handler will not be called because we redirect
           * to the Okta org for the authentication workflow.
           */
        },
        err => {
          console.log("error", err);
          throw err;
        }
      );
      if (this.widget.hasTokensInUrl()) {
        this.loading = true;
        const oktaTokenResponse = await this.widget.authClient.token.parseFromUrl();
        const accessToken = oktaTokenResponse.tokens.accessToken.value;
        const oktaUserName = oktaTokenResponse.tokens.idToken.claims.name || "";
        const oktaUserEmail =
          oktaTokenResponse.tokens.idToken.claims.email || "";

        // Use the access token to call the firebaseCustomToken endpoint.
        const firebaseTokenResponse = await fetch(
          `https://australia-southeast1-vocus-awsconnect-wallboard.cloudfunctions.net/api/firebaseCustomToken`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        const firebaseToken = await firebaseTokenResponse.text();

        // Use the Firebase custom token to authenticate with Firebase.
        try {
          await auth.signInWithCustomToken(firebaseToken);

          // Now that the user is authenticated, you can use auth-enabled
          // Firebase services. In this example, we update the Firebase
          // user profile with information from Okta:
          const currentUser = auth.currentUser;
          if (currentUser.displayName !== oktaUserName) {
            await currentUser.updateProfile({ displayName: oktaUserName });
          }
          if (currentUser.email !== oktaUserEmail) {
            await currentUser.updateEmail(oktaUserEmail);
          }
          this.loading = false;
          this.$router.push("/");
        } catch (err) {
          console.error("Error signing in with custom token.", err);
        }
      }
    });
  },
  destroyed() {
    // Remove the widget from the DOM on path change
    this.widget.remove();
  },
  computed: {
    dialog() {
      return this.$store.getters.getProfileError;
    }
  }
};
</script>

<style scoped>
.v-card {
  overflow: hidden;
  border: none;
}

.vocus-shadow {
  box-shadow: 0 30px 60px -10px rgba(41, 67, 124, 0.18) !important;
}

.welcome-splash {
  color: white !important;
  background-color: #0000ff;
  height: 100%;
}

.supersize {
  font-size: 42px;
  line-height: 1.2;
}
</style>

<style>
#okta-sign-in {
  margin: 0;
  width: auto;
  border: none;
  box-shadow: none !important;
  min-width: 220px;
}
#okta-sign-in.auth-container.main-container {
  background: transparent;
  color: #ffffff;
}
#okta-sign-in .auth-content {
  padding: 0 5px;
}
#okta-sign-in .o-form label {
  font-size: 12px;
  font-weight: normal;
}
#okta-sign-in.auth-container .button-primary {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: #0000ff;
  border: none;
  border-radius: 28px;
  box-shadow: none;
  transition: 0.3s ease;
}
#okta-sign-in.auth-container .button-primary:hover {
  background: #007fff;
  background-image: none;
  border: none;
  box-shadow: 0 12px 20px -5px rgba(41, 67, 124, 0.18);
}
.okta-sign-in-header,
.okta-form-title {
  display: none;
}
#okta-sign-in .o-form .input-fix {
  border-radius: 20px;
}
#okta-sign-in .o-form .input-fix input:-webkit-autofill {
  border-radius: 20px;
}
#okta-sign-in.auth-container.main-container .margin-btm-30 {
  margin-bottom: 12px;
}
#okta-sign-in.auth-container .okta-form-label {
  color: #ffffff;
}

#okta-sign-in .auth-footer {
  text-align: center;
  margin-top: 16px;
}
#okta-sign-in .auth-footer .link {
  color: #ffffff !important;
}
@media (min-width: 960px) {
  #okta-sign-in .auth-footer {
    margin-top: 48px;
  }
}
</style>
